/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Museo by Linotype
 * URL: https://www.myfonts.com/collections/museo-font-exljbris
 * Copyright: Copyright © 2014 Monotype Imaging Inc. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Incn*/




@font-face {
  font-family: "Museo300";
  src: url('Museo300.woff2') format('woff2'), url('Museo300.woff') format('woff');
}
@font-face {
  font-family: "Museo700Italic";
  src: url('Museo300i.woff2') format('woff2'), url('Museo300i.woff') format('woff');
}
@font-face {
  font-family: "Museo700";
  src: url('Museo700.woff2') format('woff2'), url('Museo700.woff') format('woff');
}
@font-face {
  font-family: "Museo300Italic";
  src: url('Museo700i.woff2') format('woff2'), url('Museo700i.woff') format('woff');
}

