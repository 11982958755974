//variables
$primary: #E30613 !default;
$primary-darker: #b20713 !default;
$secondary: #4D5152 !default;
$tertiary: #9D9D9C;
$theme-contrast-color: $secondary !default;


.flex-grow-2 {
  flex-grow: 2 !important
}

.bring-to-front {
  z-index: 999;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.bring-text-front {
   z-index: 999;
  text-shadow: 2px 2px 5px rgba(0,0,0,0.4);
}

.hover-primary:hover, .mobile-hamburger-color {
  color: $primary !important;
}

@import '@/src/scss/preset/sportbedrijfraalte.scss';
//@import 'mainMenu';

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";

.flex-wrapper {
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  margin-bottom: 0.8em;
  font-family: "Museo700", serif !important;
  @include media-breakpoint-down(sm) {
    font-size: 1.7rem;
  }
}
.custom-select, .form-control {
  border: 1px solid black;
}

h2, h3, h4, h5, h6 {
  color: $theme-2;
  margin-bottom: 0.8em;
  font-family: "Museo700", serif !important;
}

.h2, .h3, .h4, .h5, .h6, .btn, .nav-link, .bg-primary {
  font-family: "Museo700", serif !important;
}

.underline-theme-2 {
  color: $dark;
  border-bottom: solid $border-width $theme-2;
  text-decoration: none !important;
}

#footer-bg {
  background: url('../img/flavor-shape-01.png');
  height: 100px;
  margin-top: -115px;
  margin-bottom: -1px;
  background-size: 100% 100%;
  @include media-breakpoint-down(md) {
    height: 50px;
    margin-top: -50px;
  }
}
main {
  a:not(.btn) {
    color: $theme-4;
    &:hover {
      text-decoration: underline;
    }
  }
}

.video--banner {
  min-height: 280px;
  iframe {
    width: 130%;
    height: 130%;
    min-height: 200px;
    min-width: 300px;
  }
}

.bg-transparent-secondary {
  background-color: rgba($secondary, 0.96);
}

.h3 {
  @extend .text-theme-2;
}

.breadcrumb-item {
  a {
    color: $theme-2;
    text-decoration: underline;
  }
}

.bg-transparent-white {
  background-color: rgba($white, 0.7);
}

.content-wrapper {
  padding: 1.5rem 2rem 7rem 2rem;
  @include media-breakpoint-down(md) {
    padding: 1.5rem 1rem 3rem 1rem;
  }
}
//
//.border-radius-top-1 {
//  border-top-left-radius: 1rem !important;
//  border-top-right-radius: 1rem !important;
//}
//
//.border-radius-bottom-1 {
//  border-bottom-left-radius: 1rem !important;
//  border-bottom-right-radius: 1rem !important;
//}

#main-content {
  min-height: 60vh;
}

li::marker {
  color: $primary;
  font-size: 1.4rem;
  line-height: 0;
}

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu{ display: none; }
  .navbar .nav-item:hover .nav-link{   }
  .navbar .nav-item:hover .dropdown-menu{ display: block; }
}

.content-news-detail-wrapper {
  img {
    width: 100%;
    height: auto;
  }
}

.play-overlay i {
  top: 45%;
}

.card-text {
  line-height: 1.5;
}

article {
  border-radius: 15px;
  .border-rad-bottom {
    border: 1px solid $dark;
    border-radius: 0 0 15px 15px;
  }

  .border-rad-top {
    border-radius: 15px 15px 0 0;
  }
}

.container-fluid {
  align-items: flex-start !important;
}

.shoppingcart--icon {
  @extend .mr-2;
  @extend .my-auto;
  @extend .text-theme-2;
}

.navbar {
  padding: 0 8px;
  background: white;
  .navbar-brand {
    padding-top: 0;
    img {
      width: 250px;
    }
    @include media-breakpoint-down(sm) {
      img {
        width: 200px;
      }
    }
  }
  li.nav-item {
    margin-bottom: 6px;
    @include media-breakpoint-down(lg) {
      text-align: left;
      padding-left: 0.5em;
    }
    a {
      padding: 16px 0px 10px 0px;
      color: $secondary !important;
    }
    &.active:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $primary;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
    &:not(.active):hover:after{
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $primary;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
  }
}



.custom-hero-position {
  @media only screen and (max-width: 1200px) {
    position: relative !important;
    max-width: 100% !important;
    margin-bottom: 3rem;
  }
}

.quick-menu-item {
  background-color: $theme-1;
  color: white;
  font-family: 'Museo700';
  img {
    @extend .p-3;
  }
  @include media-breakpoint-down(md) {
    img {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
}

.w-500 {
  max-width: 600px;
}

.list-group {
  a {
    @extend .text-theme-2;
  }
}

  #rooster {
    h3, th {
      color: $primary !important;
    }
    h2 {
      text-align: left !important;
    }
    form {
      justify-content: unset !important;
    }
  }

.page-head-banner {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 500px;
  background-color: black;
  img {
    opacity: 85%;
  }
  @include media-breakpoint-down(sm) {
    max-height: 360px;
  }
}

.part--card {
  border: none;
  .btn {
    @extend .w-100;
  }
  .card-body {
    background-color: $theme-3;
  }
}

.share-kop-wrapper p{
  font-weight: 600;
}

.banner-max {
  width: 100%;
  max-height: 350px;
}

.banner-section {
  img {
    min-height: 400px;
    object-fit: cover;
  }
}

.full-width-split-action-banner {
  .bg--theme {
    @extend .bg-theme-1;
  }
  .btn--theme {
    @extend .btn-theme-2, .text-white;
    i {
      display: none;
    }
  }
}

figure.img-cover {
  width: 100%;
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    height: 170px;
  }
  @include media-breakpoint-down(sm) {
    height: 130px;
  }
}

th {
  background: none !important;
  border-top: none !important;
  border-bottom: 2px solid $primary !important;
}

.rounded-img {
  border: none;
  border-radius: 1.25rem;
  margin-bottom: .5rem;
}

.mt-c-5 {
  @include media-breakpoint-up(xxxl) {
    margin-top: 3rem !important;
  }
}

.hover--box-shadow {
  text-decoration: none !important;
  img {
    transition: all 0.3s;
  }
  &:hover {
    img {
      box-shadow: 5px 5px 15px 5px #0000000f;
    }
  }
}

.btn-outline-contrast {
  @extend .text-white;
}

.menu-block {
  border: 2px solid $white;
  border-radius: 0;
  line-height: 1.6;
  align-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  transition: all 0.3s;

  &:hover {
    background-color: $secondary;
    .text-contrast {
      color: $white !important;
    }
  }

  &.active {
    background-color: $secondary;
    .text-contrast {
      color: $white !important;
    }
  }

  a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;
    width: 100px;
    height: 100px;

    i.fas {
      display: inline-block;
      width: 100%;
    }

    &:hover {
      text-decoration: none;
    }

  }
}
.space-logo {
  @include media-breakpoint-up(md){
    margin-top: 7rem;
  }
}

@include media-breakpoint-up(lg) {
  .position-lg-static {
    position: static !important;
  }
}

.calendar-wrapper {
  input {
    text-align: left ;
  }
}

.planner-table {
  td {
    a {
      color: black !important;
      text-decoration: underline;
    }
  }
  tr:first-child {
    td {
      border-top: none;
    }
  }
  //@include media-breakpoint-down(md) {
  //  th:last-child, td:last-child {
  //    display: none !important;
  //  }
  //}
}

.word-break {
  word-break: break-word;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background: $theme-1;
}

.decenter-right {
  @include media-breakpoint-up(xxxl) {
    margin-right: 8rem !important;
    width: 50%;
  }
}

.home h2#rooster-header {
  text-align: center;
}


#form-container {
  margin-left: inherit !important;
  margin-right: inherit !important;
}

.home {
  form#rooster-form div.form-group {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  #form-container {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  h2#rooster-header {
    color: $info;
    text-align: center;
  }
}

footer {
  h4 {
    color: $theme-1;
  }
  .c-fit {
    height: 40px;
  }
  .justify-content {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .justify-content-start {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  z-index: 2;
  .social-icons {
    margin: 0;
    a {
      color: white;
      font-size: 1.5em;
    }
    i {
      &:hover {
        color: $theme-3 !important;
      }
    }
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute !important;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}

#invoices div.card-header {
  background-color: $info;
  color: $white;
  border-radius: 0;
}

#invoices div.card-footer {
  background-color: $white;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  border-radius: 0;
}

.card-banner {
  margin-top: -25px;
}

.regular {
  font-weight: 400 !important;
}

#invoices div.card-header span.js-resend-invoice {
  color: $white !important;
}

@include media-breakpoint-up(sm) {
  #side-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    z-index: -1;
  }

  #side-menu-bg {
    height: 100vh;
    background-color: $info;
  }

  .navbar {
    z-index: 2;
  }

  #topNav {
    z-index: 2;
  }
}

#topNav li.active a{
  color: $primary;
}

#footer-img {
  z-index: -2;
  position: relative;
}

.header--nav {
  @include media-breakpoint-up(md) {
    position: sticky;
  }
}

.welcome {
  color: white;
}

.navbar-nav .dropdown-menu {
  background-color: $light;
  border-radius: 0px;
  padding: 0;
  border: none;
  margin-left: -8px;
  @include media-breakpoint-down(lg) {
    padding-left: 1em;
    text-align: left;
  }
  a {
    padding: 16px 16px !important;
    color: $dark;
    //font-family: "Museo300", serif !important;
    &:hover {
      background-color: $primary;
      color: white !important;
    }
  }
}

.footer-cta-banners {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
  .footer-cta-item:hover {
    filter: contrast(0.5);
  }
}

.quick-menu-item {
  word-break: break-all;
  &:hover {
    filter: contrast(.5);
  }
}

.btn-login {
  @extend .btn-dark;
  //background-color: $dark;
}

div#Rooster {
  padding-top: 0rem !important;
}

.more-tickets {
  text-align: left;
}

.sidebar {
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  position: relative;
  z-index: 1;
  min-height: 90vh;
  margin-bottom: -3rem;
  .invalid-feedback, .was-validated {
    color: $theme-3;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 0rem;
    min-width: 100%;
    min-height: unset;
  }
  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
    .list-group-item {
      &.active {
        background: $secondary;
        border-color: $secondary;
        color: $white !important;
      }
    }
  }
}

// Calendar
$space: 1.5rem;

.calendar {
  margin: auto (-$space);
  box-shadow: 0 ($space * .125) ($space * .25) rgba(0, 0, 0, .025);


  .owl-carousel:not(.owl-loaded) {
    //padding: 0 1.5rem;
    > .item:not(:first-child) {
      display: none;
    }
  }

  .owl-carousel .item {
    position: relative;
    z-index: 100;
    -webkit-backface-visibility: hidden;
  }

  li figure {
    padding-top: 0; height: $space * 10;
    border-radius: 12px;
    overflow: hidden;
    background-size: cover;
    .inner {
      position: relative;
      text-align: center; text-shadow: 0 0.063em 0.063em*2 rgba(0,0,0,.5); color: $white;
      h5, .h5 { font-size: 1.5rem; color: white; }
    }
    .inner-wrapper {
      display: flex; align-items: center; justify-content: center;
      z-index: 1;
      height: 100%;
      background: rgba(0,0,0,.5);
      border-radius: 12px;
      overflow: hidden;
    }

    .inner,
    .inner-wrapper { @include transition(); }
  }

  .extrainfo {
    padding: $space * .5;
    a { line-height: $space; }
  }

  li figure:hover {
    .inner-wrapper {
      opacity: 0;
    }
  }
}

.small {
  font-size: 0.8rem;
}

.owl-nav {
  position: absolute;
  top: -2.25rem;
  right: 9px;
  left: 9px;
  font-size: .875rem;
  text-align: right;
  @extend .px-3;
}

.owl-carousel {
  padding: 0 9px 0 9px;
}

.calendar-item {
  border: solid 2px $primary;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 12px;
}

.double-spaced {
  height: 58px;
}

#bottom-layer {
  object-fit: cover;
}

.footer-overlap {
  // TODO: Rename in all installations to theme-spacer, since here this name doesnt make sense.
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.border-thick {
  border: solid 2px;
}

.search-btn {
  min-width: 200px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.rooster {
  h3 {
    @extend h4;
    @extend .text-theme-1;
  }
}

.card-body {
  .media-body {
    form {
      display: inline !important;
      button {
        padding-top: 0;
        padding-bottom: 0;
        height: 30px;
        margin-top: .5rem;
      }
    }
  }
}


.include-stretch-hover {
  transition: color 0.2s, fill 0.2s;
  &:hover{
    color: $primary !important;
    fill: $primary !important;
    .btn {
      background-color: #a4ab0d;
    }
    small {
      color: $primary;
    }
  }
}

.card-stretch-hover {
  transition: color 0.2s, fill 0.2s;
  &:hover{
    small {
      color: $secondary !important;
    }
    .read-more {
      text-decoration: underline;
    }
  }
}

.card-body {
  &:hover{
    .read-more {
      text-decoration: underline;
    }
  }
}

.intrest-badge {
  left: 0;
  padding: 0.8rem 1.25rem 0.8rem 1.25rem;
  top: -1.5rem;
  font-size: 100%;
  font-weight: 400;
  p {
    vertical-align: middle;
    font-size: 0.6rem;
  }
  .pl-2 {
    font-size: 13px;
  }
}

.agenda-devider {
  height: 6px;
}

.sidebar-badge-list {
  .intrest-badge {
    height: 6.2rem;
    width: 6.2rem;
  }
}

.content-sidebar {
  @include media-breakpoint-up(lg) {
    position: relative;
    right: -2rem;
    height: 100%;
  }
}

.sidebar-badge-list {
  @include media-breakpoint-up(lg) {
    margin-left: -3rem;
  }
}

.reset-position {
  top: inherit !important;
  bottom: inherit !important;
  left: inherit !important;
  right: inherit !important;
}
.sidebar-bg-container-breaker {
  position: absolute;
  top: 0;
  left: 0;
  right: -1500px;
  bottom: 0;
  z-index: -1;
  @include media-breakpoint-down(lg) {
    left: -2rem;
  }
}

.h-400 {
  height: 400px;
}

.full-round-banner {
  border-radius: 50rem;
  border: solid 4px;
  &::after{
    content: '';
    height: 2.3rem;
    width: 2.3rem;
    background: inherit;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50rem;
    transition: all 0.5s;
  }
  &:hover{
    &::after{
      bottom: 1rem;
      right: -0.5rem;
    }
  }
  img {
    border-radius: 50rem;
  }
}

//
//.force-text-danger {
//  h1, h2, h3, h4, h5, h6, a:not(.btn) {
//    color: $danger !important;
//  }
//}
//
//.force-text-info{
//  h1, h2, h3, h4, h5, h6, a:not(.btn) {
//    color: $info !important;
//  }
//}
//
//.force-text-success {
//  h1, h2, h3, h4, h5, h6, a:not(.btn) {
//    color: $success !important;
//  }
//}

.fixed-width-sidebar {
  width: 400px;
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 150px;
  }
}

.page-head-banner {
  width: 100%;
}

.page-content-wrapper {
  min-width: 100vw;
  @include media-breakpoint-up(md) {
    min-width: 0;
    width: 100%;
  }
}

.h-xl-100 {
  @media only screen and (min-width: 1200px) {
    height: 100% !important;
  }
}

.w-md-50 {
  @include media-breakpoint-up(md) {
    width: 50% !important;
  }
}

@include media-breakpoint-down(md) {
  .info--card {
    h3 {
      font-size: 20px;
    }
  }
  .sub-dropdown.show {
    border-top: solid 1px $theme-1 !important;
  }
}
@include media-breakpoint-up(md) {
   .sub-dropdown.show {
     position: absolute !important;
     @extend .m-md-0;
   }
}

.dropdown-menu:not(.sub-dropdown) {
  top: 62px !important;
}

.w-xl-100 {
  @include media-breakpoint-up(xl) {
    width: 100% !important;
  }
}

.owl-carousel {
  list-style: none;
}

.extrainfo {
  font-size: 14px;
}

.brand--image {
  max-height: 90px;
}

.has-user-content {
  table {
    @extend .table;
    th, td {
      border: none;
    }
  }
  thead {
    color: $theme-1;
    //font-family: 'Museo700';
    font-size: 1.1em;
    border-bottom: solid 2px $theme-1;
  }
}

.highlight-banners-wrapper {
  .highlight-section {
    background: $theme-3;
    position: absolute;
    width: 100%;
    height: 165px;
    bottom: -135px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 0;
    object-fit: cover;
    margin-bottom: 100px;
  }

  .highlight-card-top-img {
    width: 100%;
    height: 100% !important;
    position: relative;
    transition: height .8s;
    object-fit: cover;
  }

  .highlight-card-body {
    @extend .p-3;
    height: 100px;
    transition: all .8s;
    bottom: 0;
    width: 100%;
    position: absolute;
    background-color: rgba(255,255,255,0.65);
  }

  .highlight-read-on {
    display: none;
  }

  .highlight-card-hover-grad {
    background: linear-gradient(331deg, $primary, $primary-darker);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    bottom: 0;
  }

  .highlight-card {
    border-radius: $border-radius;
    background-color: $white;
    display: inline-block;
    outline: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    highlight-card-body {
      transition: all .4s ease-in;
      position: absolute;
      h5 {
        transition: color .3s;
      }
    }
    &:hover {
      .highlight-card-top-img {
        height: 100% !important;
      }
      .highlight-card-body {
        transform: translateY(-20px);
        z-index: 2;
        background: none;
        h5 {
          color: white;
        }
        p {
          display: none;
        }
      }
      .highlight-card-hover-grad {
        opacity: .8;
      }
      .highlight-read-on {
        display: block;
      }
    }
  }
}

.text-contrast {
  color: #ffffff !important;
}

.video-hero-wrapper {
  .container {
    @extend .position-absolute;
    @extend .text-center;
    height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .video-hero--content {
      display:none;
    }
    .video-hero--title {
      @include media-breakpoint-down(sm) {
        font-size: 1.3rem;
      }
    }
    .video-hero--subtitle {
      display: block;
      @extend small;
      @include media-breakpoint-up(lg) {
        display: inline-block;
        &::before {
          content: '- ';
          padding-left: 8px;
        }
      }
    }
    @include media-breakpoint-up(md) {
      height: 500px;
    }
  }
  h5 {
    @extend .bring-text-front;
    @extend h1;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: -100px;
    height: 500px;
  }
}

.page-link {
  border-color: $theme-1;
}

.nav-btn-minimal {
  display: inline;
}
.position-sticky {
  z-index: 1020 !important;
}
