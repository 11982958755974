// Change layout variables (with "!default" flag)
// Note: please only adjust Bootstrap variables, or make BS-compatible additions
//$enable-rounded: false !default;

@import '../webfonts/Museo.css';

//variables
$primary: #E30613 !default;
$secondary: #4D5152 !default;
$tertiary: #ececec;
$theme-1: $primary !default;
$theme-2: $secondary !default;
$theme-3: $tertiary !default;
$theme-4: #14327e !default;
$theme-contrast-color: White !default;

$primary-faded : lighten($primary, 53%);

$border-width: 2px;
$theme-colors: (
        'theme-1': $theme-1,
        'theme-2': $theme-2,
        'theme-3': $theme-3,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$font-family-base: open-sans, sans-serif;

$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;

$link-decoration: underline;

$headings-color: $primary;
$line-height-base: 2;

@import '@/src/scss/main.scss';
@import '@/src/scss/components/hero-video.scss';

html {
  scroll-behavior: smooth;
}

.accordion button::after {
  color: $primary;
}

form#CustomerForm-Login {
  small.text-muted a {
    color: $text-muted;
  }
}

.border-info {
  border: 2px solid $info;
  border-radius: 12px;
}

footer {
  .bg-theme-3 {
    a:not(.btn) {
      color: $theme-4 !important;
      text-decoration: underline;
    }
  }
}

div.photo-banner {
  position: relative;
  top: 0;
  left: 0;

  #bottom-layer {
    position: relative;
    top: 0;
    left: 0;
    max-height: 650px;
    min-height: 250px;
    width: 100%;
  }

  #top-layer {
    position: absolute;
    bottom: -2px;
    left: 0;
    max-height: 150px;
    width: 100%;
  }
}

div.social-icons {
  margin-left: 10px;
  display: inline-block;

  i {
    @extend  .mx-1;
    &:hover {
      color: $primary !important;
    }
  }
}

.border-bottom-1 {
  border-bottom: solid 1px;
}


.top-menu {
  color: $dark;
  font-weight: 400;
  font-size: 16px;
  ul {
    margin-bottom: 0;

    li {
      margin: 16px 10px 4px 10px;
      list-style: none;

      a {
        color: $secondary;
        text-decoration: none;
        &:hover {
          color: $primary;
        }

      }

      .active {
        a {
          color: $info;
        }
      }
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $white;
  @include media-breakpoint-down(lg) {
    font-size: 0.82rem;
  }
}

.card-body-overlay {
  bottom: 0;
  left: 0;
  right: 0;
}

.nav-cart {
  @extend .position-relative;
  .nav-link {
    @extend .px-lg-2;
    &.disabled {
      .badge {
        display: none;
      }
    }
  }
  .badge {
    @extend .ml-2, .ml-lg-0;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
}

.banner-img {
  max-height: 500px;
  object-fit: cover;
  height: 100% !important;
}
